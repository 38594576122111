import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress"; //Progress bar
import Snackbar from "@mui/material/Snackbar";

import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import "./PDFViewer.css"; // Import your CSS file
//import "react-pdf/dist/Page/TextLayer.css";
//import "react-pdf/dist/Page/AnnotationLayer.css";
import DrawingLayer from "./DrawingLayer";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFViewer = ({ pdfURL, propPageNumber, mode, activeTool }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [viewMode, setViewMode] = useState("single"); // Dropdown state for viewing mode
  const [scale, setScale] = useState(1);
  const [isCached, setIsCached] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const pdfContainerRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [encodedPdfURL, setEncodedPdfURL] = useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu
  };

  useEffect(() => {
    // Base64 encode the original URL
    const encodedUrl = btoa(pdfURL);
    setEncodedPdfURL(encodedUrl);
  }, [pdfURL]);

  const decodePdfUrl = (encodedUrl) => {
    // Decode the Base64 encoded URL
    return atob(encodedUrl);
  };

  useEffect(() => {
    const checkCachedPDF = async () => {
      const cache = await caches.open('cache-v1');
      const cachedResponse = await cache.match(pdfURL);
      setIsCached(!!cachedResponse);

      // Check localStorage to see if PDF is cached
      localStorage.removeItem('pdfCached');
    };

    checkCachedPDF();

    if (propPageNumber) {
      setPageNumber(propPageNumber);
    }
  }, [propPageNumber, pdfURL]);

  // Check cached assets
  const cachePDF = async () => {
    setIsDownloading(true); // Show progress bar
    console.log("Download started...");
    const response = await fetch(pdfURL);
    const reader = response.body.getReader();
    const contentLength = +response.headers.get('Content-Length'); // Get the total size of the file
  
    let receivedLength = 0; // Amount of data downloaded so far
    const chunks = [];
  
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
  
      chunks.push(value);
      receivedLength += value.length;
  
      // Update the progress
      const progressPercentage = Math.floor((receivedLength / contentLength) * 100);
      console.log(`Progress: ${progressPercentage}%`);
      setProgress(progressPercentage);
    }
  
    const cache = await caches.open('cache-v1');
    const blob = new Blob(chunks); // Create a Blob from the chunks
    const responseBlob = new Response(blob);
    await cache.put(pdfURL, responseBlob); // Cache the downloaded PDF
  
    setIsCached(true); // Update state after caching
    setIsDownloading(false); // Hide progress bar after download
    console.log("Download completed");
  
    // Show the snackbar after download
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAudioPlay = (voiceFileName) => {
    const audioElements = document.getElementsByTagName("audio");
    for (let i = 0; i < audioElements.length; i++) {
      const audioElement = audioElements[i];
      if (voiceFileName === audioElement.src) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    }
  };
  const onNextPage = () => {
    const pagesToSkip = viewMode === "single" ? 1 : viewMode === "two-page" ? 2 : 3;
    if (pageNumber + pagesToSkip <= numPages) {
      setPageNumber(pageNumber + pagesToSkip);
    }
  };
  const onPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const onPageNumberChange = (e) => {
    const value = e.target.value;
    const pageNum = parseInt(value, 10);

    if (value === "" || (pageNum > 0 && pageNum <= numPages)) {
      setPageNumber(pageNum);
    }
  };

  // Handle the view mode change (dropdown)
  const handleViewModeChange = (e) => {
    setViewMode(e.target.value);
  };

  // Full screen PDF
  const toggleFullscreen = () => {
    if (isFullscreen) {
      document.exitFullscreen();
      document.querySelector('.react-pdf__Document').style.marginTop = '0';
    } else if (pdfContainerRef.current) {
      pdfContainerRef.current.requestFullscreen();
      document.querySelector('.react-pdf__Document').style.marginTop = '40px';
    }
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") onNextPage();
      if (event.key === "ArrowLeft") onPrevPage();
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [pageNumber]);

  const renderPages = () => {
    switch (viewMode) {
      case "single":
        return (
          <div className="pdf-single-page">
            <Page pageNumber={pageNumber} renderMode="canvas" scale={scale} wrap/>
          </div>
        );
      case "double":
        return (
          <div className="pdf-double-page">
            <Page pageNumber={pageNumber} renderMode="canvas" scale={scale} />
            {pageNumber < numPages && (
              <Page pageNumber={pageNumber + 1} renderMode="canvas" scale={scale} />
            )}
          </div>
        );
      case "nextDouble":
        return (
          <div className="pdf-double-page">
            {pageNumber + 1 <= numPages && (
              <Page pageNumber={pageNumber + 1} renderMode="canvas" scale={scale} />
            )}
            {pageNumber + 2 <= numPages && (
              <Page pageNumber={pageNumber + 2} renderMode="canvas" scale={scale} />
            )}
          </div>
        );
      default:
        return (
          <div className="pdf-single-page">
            <Page pageNumber={pageNumber} renderMode="canvas" scale={scale} />
          </div>
        );
    }
  };
  
    // Zoom (in & out feature)
    const zoomIn = () => {
      setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level of 3
    };
    const zoomOut = () => {
      setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom level of 0.5
    };
    const resetZoom = () => {
      setScale(1); // Default zoom level is 1
    };

  return (
    <div
      onContextMenu={handleContextMenu}
      className="pdf-container"
      style={{overflowX: mode == 'digital'?  'scroll' : 'visible'}}
      ref={pdfContainerRef}
      >
      {/* Display Progress Bar */}
      {isDownloading && (
        <div className="progress-bar-container">
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ width: "100%", height: "10px", marginBottom: "10px" }}
          />
          <span className="progress-text">{progress}%</span>
        </div>
      )}

      {/* Snackbar for download notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success" sx={{ width: '100%' }}>
          Book downloaded and cached successfully!
        </Alert>
      </Snackbar>
    
      <div className="page-info">
      <Tooltip title="Zoom In">
        <ZoomInIcon onClick={zoomIn} />
      </Tooltip>

      <Tooltip title="Reset Zoom">
        <RestartAltIcon onClick={resetZoom} />
      </Tooltip>

      <Tooltip title="Zoom Out">
        <ZoomOutIcon onClick={zoomOut} />
      </Tooltip>
        <Tooltip title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}>
          {isFullscreen ? <FullscreenExitIcon onClick={toggleFullscreen} className='fullscreen-icon' /> : <FullscreenIcon onClick={toggleFullscreen} className='fullscreen-icon'/>}
      </Tooltip>
        <Tooltip title={isCached ? "Book is available for offline use" : "Download Book"}>
          {isCached ? <OfflinePinIcon /> : <CloudDownloadIcon onClick={cachePDF} />}
      </Tooltip>
        {mode == "digital" && (
          <>
            <label htmlFor="viewMode">View Mode: </label>
            <select
              id="viewMode"
              value={viewMode}
              onChange={handleViewModeChange}
            >
              <option value="single">{`Page ${pageNumber}`}</option>
              <option value="double">{`Page ${pageNumber}-${
                pageNumber + 1 > numPages ? numPages : pageNumber + 1
              }`}</option>
              <option value="nextDouble">{`Page ${pageNumber + 1}-${
                pageNumber + 2 > numPages ? numPages : pageNumber + 2
              }`}</option>
            </select>
          </>
        )}
        <span style={{ marginLeft: "10px" }}>
          <button
            className="prev-button"
            onClick={onPrevPage}
            disabled={pageNumber <= 1}
          >
            &#x2039; {/* Left arrow */}
          </button>
          Page{" "}
          <input
            type="number"
            value={pageNumber}
            onChange={onPageNumberChange}
            min="1"
            max={numPages}
            className="page-number-input"
          />{" "}
          of {numPages}
          <button
            className="next-button"
            onClick={onNextPage}
            disabled={pageNumber >= numPages}
          >
            &#x203A; {/* Right arrow */}
          </button>
        </span>
      </div>
      <Document
        file={decodePdfUrl(encodedPdfURL)}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        <div className="pdf-pages">{renderPages()}</div>
        {activeTool !== null && <DrawingLayer activeTool={activeTool} />}
      </Document>
      <div className="pdf-navigation">
      </div>
    </div>
  );
};

export default PDFViewer;
