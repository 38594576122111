import React, { useState, useCallback, useRef, useEffect } from "react";
import { getStroke } from "perfect-freehand";

function getSvgPathFromStroke(stroke) {
  if (!stroke.length) return "";

  const d = stroke.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
      return acc;
    },
    ["M", ...stroke[0], "Q"]
  );

  d.push("Z");
  return d.join(" ");
}

export default function StrokeTool({ options, color }) {
  const [strokes, setStrokes] = useState([]);
  const [currentStroke, setCurrentStroke] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const svgRef = useRef(null);

  const getCoordinates = useCallback((e) => {
    if (!svgRef.current) return [0, 0];
    const svgRect = svgRef.current.getBoundingClientRect();
    return [e.clientX - svgRect.left, e.clientY - svgRect.top];
  }, []);

  const handlePointerDown = useCallback(
    (e) => {
      setIsDrawing(true);
      const [x, y] = getCoordinates(e);
      setCurrentStroke([[x, y, e.pressure]]);
    },
    [getCoordinates]
  );

  const handlePointerMove = useCallback(
    (e) => {
      if (!isDrawing) return;
      const [x, y] = getCoordinates(e);
      setCurrentStroke((prev) => [...prev, [x, y, e.pressure]]);
    },
    [isDrawing, getCoordinates]
  );

  const handlePointerUp = useCallback(() => {
    if (!isDrawing) return;
    setIsDrawing(false);
    setStrokes((prev) => [...prev, currentStroke]);
    setCurrentStroke([]);
  }, [isDrawing, currentStroke]);

  // const handleSave = () => {
  //   const svgContent = svgRef.current?.outerHTML;
  //   console.log("SVG Content:", svgContent);
  // };

  // const handleClear = () => {
  //   setStrokes([]);
  //   setCurrentStroke([]);
  // };

  return (
    <div>
      <svg
        ref={svgRef}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onPointerLeave={handlePointerUp}
        style={{
          padding: "0px",
          margin: "0px",
          border: "0px",
          background: "transparent",
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          display: "block",
        }}
      >
        {strokes.map((stroke, index) => (
          <path
            key={index}
            d={getSvgPathFromStroke(getStroke(stroke, options))}
            fill={color}
          />
        ))}
        {currentStroke.length > 0 && (
          <path
            d={getSvgPathFromStroke(getStroke(currentStroke, options))}
            fill={color}
          />
        )}
      </svg>
    </div>
  );
}
