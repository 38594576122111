import React from "react";
import StrokeTool from "./actionBar/StrokeTool";

const DrawingLayer = ({ activeTool }) => {
  const marker = {
    options: {
      size: 20,
      thinning: 0,
      smoothing: 0,
      streamline: 0,
      easing: (t) => t,
      start: {
        taper: 0,
        easing: (t) => t,
        cap: true,
      },
      end: {
        taper: 0,
        easing: (t) => t,
        cap: true,
      },
    },
    color: "rgba(255, 255, 0, 0.3)",
  };

  const pen = {
    options: {
      size: 10,
      thinning: 0,
      smoothing: 0.5,
      streamline: 0.5,
      easing: (t) => t,
      start: {
        taper: 50,
        easing: (t) => t,
        cap: true,
      },
      end: {
        taper: 50,
        easing: (t) => t,
        cap: true,
      },
    },
    color: "red",
  };

  return (
    <div
      className="drawing-layer"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents:
          activeTool === "pen" || activeTool === "marker" ? "auto" : "none",
      }}
    >
      <div
        className="Canvas content"
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {activeTool === "pen" && (
          <StrokeTool options={pen.options} color={pen.color} />
        )}
        {activeTool === "marker" && (
          <StrokeTool options={marker.options} color={marker.color} />
        )}
      </div>
    </div>
  );
};

export default DrawingLayer;
