import React from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import DrawIcon from "@mui/icons-material/Draw";
import IconButton from "@mui/material/IconButton";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import Stack from "@mui/material/Stack";
import "./ActionBar.css";
function ActionBar({ setActiveTool }) {
  // Accept setActiveTool prop
  return (
    <Stack
      className="ActionBar"
      direction="column"
      spacing={2}
      sx={{ alignItems: "center" }}
    >
      <IconButton
        title="Pen tool"
        aria-label="Pen tool"
        size="large"
        sx={{ color: "White" }}
        onClick={() => setActiveTool("pen")}
      >
        <DrawIcon fontSize="large" />
      </IconButton>

      <IconButton
        title="Marker Tool"
        size="large"
        sx={{ color: "White" }}
        onClick={() => setActiveTool("marker")}
      >
        <BorderColorIcon fontSize="large" />
      </IconButton>

      <IconButton
        title="Mask Tool"
        size="large"
        sx={{ color: "White" }}
        onClick={() => setActiveTool("mask")}
      >
        <HighlightAltIcon fontSize="large" />
      </IconButton>

      <IconButton
        title="Comment Tool"
        size="large"
        sx={{ color: "White" }}
        onClick={() => setActiveTool("comment")}
      >
        <ChatBubbleIcon fontSize="large" />
      </IconButton>

      <IconButton
        title="Text Tool"
        size="large"
        sx={{ color: "White" }}
        onClick={() => setActiveTool("text")}
      >
        <TextFieldsIcon fontSize="large" />
      </IconButton>
    </Stack>
  );
}

export default ActionBar;
